import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
export const FULL_WITH_BULLETS_PARAGRAPH_WRAPPER_MAX_WIDTH = "1100px";
export const StyledFullWithBulletsParagraphComponent = styled.div`
  max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
  margin: auto;

  .full-with-bullets-paragraph-wrapper {
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 0px 32px;
  }
`;
