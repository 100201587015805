import styled from "@emotion/styled";
import Colors from "styles/colors";
import { BRAND_AND_WORK_MANAGEMENT_LIGHT_TINT_02 } from "styles/color-consts";
export const StyledSideBySideTabsAndTestimonialTabsComponent = styled.div`
  .mobile-wrapper {
    .vertical-timer-tab-mobile-component {
      padding-bottom: 32px;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        display: block;
        height: 1px;
        width: 100%;
        background-color: ${Colors[BRAND_AND_WORK_MANAGEMENT_LIGHT_TINT_02]};
      }

      .vertical-timer-tab-mobile-content-container {
        padding-bottom: 20px;

        .testimonial {
          border-radius: 8px;
        }
      }

      .vertical-timer-tab-mobile-title {
        padding-top: 12px;
        gap: 8px;
        padding-bottom: 12px;

        .vertical-timer-tab-arrow-wrapper {
          display: flex;
        }
      }
    }
  }

  .tab-content {
    padding-right: 32px;

    .full-with-bullets-paragraph-component {
      .full-with-bullets-paragraph-wrapper {
        padding: 0;
      }
    }
  }
`;
