function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { positionTypes } from "segments/desktop/core-components/images-grid/images-grid-service";
export const TAB_CONTENT_DEFAULT_PROPS = {
    topic: {},
    title: null,
    bulletsConfig: null
};
export default {
    maxLimit: 4,
    isFullScreen: true,
    showDivider: true,
    backgroundColor: "transparent",
    tabsTitle: {
        title: "A work solution that works the way you do"
    },
    tabs: [
        {
            title: "For leadership",
            content: _object_spread_props(_object_spread({}, TAB_CONTENT_DEFAULT_PROPS), {
                body: "Use Time Tracking to bill clients more accurately. Gain more confidence in estimating future costs."
            }),
            asset: {
                image: {
                    src: "/static/img/segment-layout-default-images/asset/timeline-view.png"
                }
            }
        },
        {
            title: "For managers",
            content: _object_spread_props(_object_spread({}, TAB_CONTENT_DEFAULT_PROPS), {
                body: "Gain greater visibility and transparency over the work being done with robust dashboards and project overviews of where initiatives stand."
            })
        },
        {
            title: "For teams and individuals",
            content: _object_spread_props(_object_spread({}, TAB_CONTENT_DEFAULT_PROPS), {
                body: "Use Time Tracking to bill clients more accurately. Gain more confidence in estimating future costs."
            }),
            asset: {
                image: {
                    src: "/static/img/segment-layout-default-images/asset/timeline-view.png"
                }
            }
        }
    ],
    timerEnabled: false,
    showButton: false,
    arrowSize: "12px",
    showArrow: true,
    assetPosition: positionTypes.CENTER_CENTER,
    title: {
        fontSize: "24px",
        selectedFontSize: "24px"
    }
};
